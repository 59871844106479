<template>
  <router-view />
</template>

<style>
:root {
  --scroll-slider-top: 0px;
  --scroll-fader: 1;
  --scroll-maskout: 50vw;
  --doc-height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
html,
body {
  height: 100vh;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
