import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import axios from "axios";
import VueAxios from "vue-axios";
import { VueReCaptcha } from "vue-recaptcha-v3";

axios.defaults.baseURL = process.env.VUE_APP_CMS_URL;
axios.defaults.headers.common = {
  Authorization: `Bearer ${process.env.VUE_APP_CMS_TOKEN}`,
};
export default axios;

import "material-icons/iconfont/material-icons.css";

let locale = window.location.pathname.replace(/^\/([^/]+).*/i, "$1");

if (
  window.location.pathname == "" ||
  window.location.pathname == "/" ||
  (locale != "lu" && locale != "en" && locale != "fr")
) {
  window.location.href = "/" + process.env.VUE_APP_LANG;
} else {
  createApp(App)
    .use(router)
    .use(VueAxios, axios)
    .use(VueReCaptcha, {
      siteKey: "6LdIIMojAAAAAO_kbG6So9eSZck_LK6EdZVpClc4",
      loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true,
      },
    })
    .mount("#app");
}
