<template>
  <div>
    <div class="project-outer">
      <div class="project-container">
        <h3 class="project-number">{{ numberFormat }}</h3>
        <h1 class="project-header">{{ title }}</h1>
        <div
          class="project-image-container"
          :style="getBackgroundImage()"
        ></div>
        <div class="project-line"></div>
        <h3 class="project-view">open the project</h3>
        <h3 class="project-view-close">close the project</h3>
      </div>
      <div class="project-info">
        <h3 class="project-number">{{ numberFormat }}</h3>
        <h1 class="project-header">{{ title }}</h1>
        <p class="project-description">
          {{ description }}
        </p>
      </div>
    </div>
    <div class="project-content" v-if="projectBuilder.length > 0">
      <div class="project-content-wrapper">
        <div v-for="pb in projectBuilder" :key="pb">
          <div
            class="pb-imagetext"
            v-if="pb.__component == 'projectbuilder.image-text'"
          >
            <div class="pb-imagetext-content">
              <img
                :src="cmsURL + '/' + pb.Media.data.attributes.url"
                class="pb-imagetext-image"
              />
            </div>
            <div class="pb-imagetext-content">
              <p class="project-description">
                {{ pb.Text }}
              </p>
            </div>
          </div>

          <div
            class="pb-mediagallery"
            v-if="pb.__component == 'projectbuilder.media-gallery'"
          >
            <div class="pb-mediagallery-content">
              <div v-for="media in pb.Media.data" :key="media">
                <img
                  class="pb-mediagallery-image"
                  :src="cmsURL + media.attributes.url"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div
            class="pb-mediavideo"
            v-if="pb.__component == 'projectbuilder.media-video'"
          >
            <div class="pb-mediavideo-content">
              <video
                :src="cmsURL + pb.Media.data.attributes.url"
                controls
              ></video>
            </div>
            <div class="pb-mediavideo-content">
              <h1 class="project-header">{{ pb.Title }}</h1>

              <p class="project-description">
                {{ pb.Description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
  props: {
    number: Number,
    title: String,
    image: String,
    description: String,
    cmsid: Number,
  },
  data() {
    return {
      numberFormat: 0,
      projectBuilder: [],
      cmsURL: "",
    };
  },
  mounted() {
    this.syncData();
  },
  methods: {
    syncData: function () {
      this.cmsURL = process.env.VUE_APP_CMS_URL;
      this.numberFormat = this.number.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });

      this.axios
        .get("/api/projects/" + this.cmsid + "?populate=deep")
        .then((response) => {
          this.projectBuilder = response.data.data.attributes.ProjectBuilder;
        });
    },
    getBackgroundImage: function () {
      return { backgroundImage: "url('" + this.image + "')" };
    },
  },
  watch: {
    cmsid() {
      this.projectopenanimation = true;

      // Close projects
      var visibles = document.getElementsByClassName("visible");
      while (visibles.length) {
        visibles[0].classList.remove("visible");
      }

      // Close projects
      var projects = document.getElementsByClassName("projectopen");
      while (projects.length) {
        projects[0].classList.remove("projectopen");
      }

      this.syncData();
    },
  },
};
</script>

<style>
.project-container {
  width: 50%;
  height: 30vh;

  padding: 0;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.project-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  display: none;
}

.project-outer:hover .project-image-container {
  height: calc(50vw * 0.45);
  margin: -15vh 0 0 0;

  cursor: pointer;
}

.project-outer:hover .project-line {
  transform: translateY(calc(50vw * 0.45 / 1.7));
}

.project-outer:hover .project-view {
  transform: translateY(calc(50vw * 0.45 / 1.8));
  opacity: 1;
}

.project-outer:hover .project-header {
  transform: translateY(100%);
}

.project-outer:hover .project-number {
  transform: translateY(-100%);
}

.project-image-container {
  position: absolute;
  top: 50%;

  height: 0;
  width: 100%;
  transition: all 400ms;

  background-position: center;
  background-size: cover;
}

.project-number {
  margin: 0 0 0 4vw;
  padding: 0;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 10pt;

  color: #707070;
  transition: all 250ms ease-in-out;
}

.project-view {
  margin: 0 0 0 5vw;
  padding: 0;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 10pt;

  color: #707070;

  transform: translateY(0);
  transition: all 400ms ease-in-out;

  opacity: 0;
}

.project-view-close {
  margin: 0 0 0 5vw;
  padding: 0;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 10pt;

  color: #707070;

  transform: translate(4vw, calc(50vw * 0.45 / 1.8));
  transition: all 400ms ease-in-out;

  opacity: 0;
}

.project-header {
  margin: 0 0 0 4vw;
  padding: 0;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 20pt;

  color: #707070;

  transition: all 400ms ease-in-out;
}

.project-description {
  margin: 0 4vw 0 4vw;
  padding: 0;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 12pt;

  color: #707070;

  text-align: left;

  transition: all 400ms ease-in-out;
}

.project-line {
  width: 2px;
  height: 10vh;

  background-color: #707070;
  margin: 0 0 0 4vw;

  transform: translateY(0);
  transition: all 400ms ease-in-out;
}

.project-info .project-header {
  transform: translateX(-5vw);
  opacity: 0;
}

.project-info.visible .project-header {
  transform: translateX(0);
  opacity: 1;
}

.project-info .project-description {
  transform: translateX(-5vw);
  opacity: 0;
}

.project-info.visible .project-description {
  transform: translateX(0);
  opacity: 1;
}

.project-image-container.visible {
  height: calc(50vw * 0.45);
  margin: -15vh 0 0 0;
}

.project-line.visible {
  transform: translateY(calc(50vw * 0.45 / 1.7));
}

.project-view.visible {
  transform: translateY(calc(50vw * 0.45 / 1.8));
  opacity: 1;
  display: none;
}

.project-view-close.visible {
  transform: translate(0, calc(50vw * 0.45 / 1.8));
  opacity: 1;
  display: flex;
}

.project-content {
  opacity: 1;
  transition: all 500ms ease;

  position: absolute;
  width: 100vw;
  max-height: 0;

  display: flex;
  margin-top: 20vh;
  margin-bottom: -20vh;
  padding-bottom: 0;
  transform: scaleY(0);
  transform-origin: top;

  overflow: hidden;
}

.project-content.hide {
  display: none;
}

.project-content.projectopen {
  display: flex;
}

.project-content.visible {
  position: relative;

  max-height: fit-content;
  transform: scaleY(1);
  padding-bottom: 5vh;
}

.project-content-wrapper {
  margin: 10vh 0 10vh 0;

  width: 100%;
}

.pb-imagetext {
  display: flex;
  margin-bottom: 5vh;
}

.pb-imagetext-content {
  flex: 1;

  text-align: left;
}

.pb-imagetext-content:nth-child(2) {
  flex: 1;

  text-align: left;
  padding: 4vh 4vw 4vh 0;
}

.pb-imagetext-image {
  width: 50vw;
}

.pb-mediagallery {
  margin-bottom: 5vh;
}

.pb-mediagallery-content {
  display: flex;
  overflow-x: auto;
}

.pb-mediagallery-image {
  height: 70vh;
  margin: 0 5vw 0 0;
}

.pb-mediagallery-content div:last-child .pb-mediagallery-image {
  margin: 0;
}

.pb-mediavideo {
  width: 100vw;
  padding: 0 0 10vh 0;

  display: flex;
}

.pb-mediavideo-content {
  flex: 1;

  display: flex;
  flex-direction: column;

  align-content: flex-start;
  align-items: flex-start;

  justify-content: flex-start;
  justify-items: flex-start;
}

.pb-mediavideo .pb-mediavideo-content video {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .project-outer {
    flex-direction: column;
    padding: 10vh 0 0 0 !important;
  }

  .project-info {
    width: 95vw;
  }

  .project-container {
    width: 100vw;
    height: 40vh;
  }

  .project-outer:hover .project-image-container {
    height: 40vh;
    margin: 0;
  }

  .project-outer:hover .project-line {
    transform: translateY(calc(50vw * 0.45 / 0.65));
  }

  .project-outer:hover .project-view {
    transform: translateY(calc(50vw * 0.45 / 0.75));
  }

  .project-outer .project-image-container {
    height: 40vh;
    margin: 0;
  }

  .project-image-container {
    height: 40vh;
    top: 0;
  }

  .project-view {
    margin: 0 0 0 8vw;
  }

  .project-view-close {
    margin: 0 0 0 8vw;
    transform: translate(0, calc(50vw * 0.45 / 1.8));
  }

  .project-content {
    margin-top: 0;
  }

  .pb-mediagallery-image {
    height: 30vh;
  }

  .project-number {
    margin: 7vh 0 0 8vw;
  }

  .project-outer .project-line {
    transform: translateY(calc(50vw * 0.45 / 0.75));
  }

  .project-outer .project-view {
    transform: translateY(calc(50vw * 0.45 / 0.85));
    opacity: 1;
  }

  .project-outer:hover .project-line {
    transform: translateY(calc(50vw * 0.45 / 0.75));
  }

  .project-outer:hover .project-view {
    transform: translateY(calc(50vw * 0.45 / 0.85));
    opacity: 1;
  }

  .project-outer .project-header {
    transform: translateY(100%);
  }

  .project-outer .project-number {
    transform: translateY(-100%);
  }

  /* visible */

  .project-info.visible .project-header {
    transform: translateX(0);
    opacity: 1;
  }

  .project-info.visible .project-number {
    opacity: 0;
  }

  .project-info.visible .project-description {
    transform: translateX(0);
    opacity: 1;
  }

  .project-view.visible {
    transform: translateY(calc(50vw * 0.45 / 1.8));
    opacity: 1;
    display: none;
  }

  .project-view-close.visible {
    transform: translate(0, calc(50vw * 0.45 / 0.95));
    opacity: 1;
    display: flex;
  }

  .project-wrapper {
    margin-bottom: 10vh;
  }

  .project-wrapper:last-child {
    margin-bottom: 10vh;
  }

  .pb-mediavideo {
    flex-direction: column;
  }

  .pb-mediavideo-content {
    margin-top: 5vh;
  }
}
</style>
