<template>
  <div :class="[darkMode ? 'home-dark' : 'home']" id="top">
    <Menu ref="menuChild" @closeMenu="closeMenu" @customScreen="changeScreen"></Menu>
    <div class="menulogocontainer">
      <h1 class="menubutton logo" :class="darkMode ? 'darkmode' : 'lightmode'" v-if="!menuOpen" @click="changeScreen(1)">
        imagify visual studio
      </h1>
    </div>
    <div class="menubuttoncontainer">
      <transition name="slidedownmenubtnmode">
        <h1 class="menubutton mode hidden" :class="darkMode ? 'darkmode' : 'lightmode'" v-if="!menuOpen"
          @click="toggleMode">
          <div class="letter-change-container">
            <div class="letter-container">
              <transition name="slideletter">
                <span class="menubutton-letter" v-if="darkModeText">l</span>
              </transition>
              <transition name="slideletter">
                <span class="menubutton-letter" v-if="darkModeText">i</span>
              </transition>
              <transition name="slideletter">
                <span class="menubutton-letter" v-if="darkModeText">g</span>
              </transition>
              <transition name="slideletter">
                <span class="menubutton-letter" v-if="darkModeText">h</span>
              </transition>
              <transition name="slideletter">
                <span class="menubutton-letter" v-if="darkModeText">t</span>
              </transition>
            </div>

            <div class="letter-container">
              <transition name="slideletter">
                <span class="menubutton-letter" v-if="!darkModeText">d</span>
              </transition>
              <transition name="slideletter">
                <span class="menubutton-letter" v-if="!darkModeText">a</span>
              </transition>
              <transition name="slideletter">
                <span class="menubutton-letter" v-if="!darkModeText">r</span>
              </transition>
              <transition name="slideletter">
                <span class="menubutton-letter" v-if="!darkModeText">k</span>
              </transition>
            </div>
          </div>

          <div class="letter-container-static">
            <span class="menubutton-letter">m</span>
            <span class="menubutton-letter">o</span>
            <span class="menubutton-letter">d</span>
            <span class="menubutton-letter">e</span>
          </div>
        </h1>
      </transition>
      <transition name="slidedownmenubtn">
        <h1 class="menubutton main" :class="darkMode ? 'darkmode' : 'lightmode'" v-if="!menuOpen" @click="toggleMenu">
          menu
        </h1>
      </transition>
    </div>

    <div class="page-home">
      <div class="section">
        <div class="section-content" v-if="currentScreen == 1" :class="darkMode ? 'darkmode' : 'lightmode'">
          <div class="overflowhidden">
            <h1 :class="[imageSlide ? 'textslideside' : 'textslidesidestill']">
              {{ welcomePageTitle }}
            </h1>
          </div>
          <p :class="[imageSlide ? 'textslideout' : 'textstill']">
            {{ welcomePageDescription }}
          </p>
        </div>

        <div v-for="(cl, index) in categoryList" v-bind:key="index">
          <div class="section-content" :class="darkMode ? 'darkmode' : 'lightmode'" v-if="currentScreen == index + 2">
            <div class="overflowhidden">
              <h1 :class="[imageSlide ? 'textslideside' : 'textslidesidestill']">
                {{ cl.name }}
              </h1>
            </div>

            <p :class="[imageSlide ? 'textslideout' : 'textstill']">
              {{ cl.description }}
            </p>
          </div>
        </div>

        <div class="navigation" :class="[imageSlide ? 'textslideside' : 'textslidesidestill']">
          <div class="navigation-element" :class="darkMode ? 'darkmode' : 'lightmode'">
            <div class="navigation-text" v-on:click="previousScreen()">
              <label v-if="currentScreen == 2">Welcome</label>
            </div>
            <div class="navigation-text" v-on:click="previousScreen()">
              <label v-if="categoryList[currentScreen - 3]">{{
                categoryList[currentScreen - 3].name
              }}</label>
            </div>
            <div class="navigation-line"></div>
          </div>
          <div class="navigation-element" :class="darkMode ? 'darkmode' : 'lightmode'">
            <div class="navigation-line" v-if="currentScreen != 1"></div>
            <div class="navigation-text" v-if="currentScreen != 1" v-on:click="nextScreen()">
              <label v-if="categoryList[currentScreen - 1]">{{
                categoryList[currentScreen - 1].name
              }}</label>
            </div>

            <div class="navigation-line anim" v-if="currentScreen == 1"></div>
            <div class="navigation-text anim" v-if="currentScreen == 1" v-on:click="nextScreen()">
              <label v-if="categoryList[currentScreen - 1]">{{
                categoryList[currentScreen - 1].name
              }}</label>
            </div>
          </div>

          <!--
					<span
						class="material-icons navigation-arrow-reverse navigation-button"
						@click="previousScreen()"
						>chevron_right</span
					>
					<span
						class="material-icons navigation-arrow navigation-button"
						@click="nextScreen()"
						>chevron_right</span
					>-->
        </div>
      </div>
      <div class="section parallax-container">
        <div class="image-container-1" v-if="currentScreen == 1" :class="{ maskout: menuOpen || imageSlide }"
          :style="{ backgroundImage: `url(${welcomePageImage})` }"></div>

        <div v-for="(cli, index) in categoryList" v-bind:key="index">
          <div class="image-container-1" v-if="currentScreen == index + 2" :class="{ maskout: menuOpen || imageSlide }"
            :style="{
              backgroundImage: `url(${cli.url})`,
            }"></div>
        </div>
      </div>

      <div class="section mobile">
        <div class="image-container-1" v-if="currentScreen == 1" :class="{ maskout: menuOpen || imageSlide }"
          :style="{ backgroundImage: `url(${welcomePageImage})` }"></div>

        <div v-for="(cli, index) in categoryList" v-bind:key="index">
          <div class="image-container-1" v-if="currentScreen == index + 2" :class="{ maskout: menuOpen || imageSlide }"
            :style="{
              backgroundImage: `url(${cli.url})`,
            }"></div>
        </div>
      </div>
    </div>

    <div class="home-projects" :class="darkMode ? 'darkmode' : 'lightmode'" v-if="currentScreen > 1">
      <div v-for="(prjct, index) in categoryList[currentScreen - 2].projects" v-bind:key="index" class="project-wrapper">
        <Project :number="index + 1" :title="prjct[index].attributes.Name"
          :description="prjct[index].attributes.Description" :cmsid="prjct[index].id" :image="cmsURL + prjct[index].attributes.CoverMedia.data.attributes.url
            " @click="openProject(index)" :id="'project-' + index"></Project>
      </div>
    </div>

    <div class="home-projects welcome" :class="darkMode ? 'darkmode' : 'lightmode'" v-if="currentScreen == 1">
      <div class="welcome-line mobile"></div>
      <div class="home-welcome-screen-1">
        <div class="welcome-container" id="aboutus">
          <div class="welcome-flex">
            <p class="welcome-text1" :class="darkMode ? 'darkmode' : 'lightmode'">
              {{ welcomeText1 }}
            </p>
          </div>
          <div class="welcome-flex"></div>
        </div>
        <div class="welcome-container">
          <div class="welcome-flex"></div>
          <div class="welcome-flex">
            <p class="welcome-text2" :class="darkMode ? 'darkmode' : 'lightmode'">
              {{ welcomeText2 }}
            </p>
          </div>
        </div>
      </div>

      <div class="home-welcome-screen-2">
        <div class="welcome-line" id="contact"></div>
        <div class="welcome-contact">
          <div class="welcome-contact-address" :class="darkMode ? 'darkmode' : 'lightmode'">
            <label class="welcome-contact-address-item">34, Route de Mersch</label><br />
            <label class="welcome-contact-address-item">L-7410 Angelsberg</label><br />
            <label class="welcome-contact-address-item">Luxembourg</label><br />
            <br />
            <label class="welcome-contact-address-item last">+352 26 32 22 17</label>
          </div>
          <div></div>
        </div>
        <div class="welcome-contact second">
          <div></div>
          <div class="welcome-contact-address">
            <h2 class="welcome-contact-heading" :class="darkMode ? 'darkmode' : 'lightmode'">
              Let’s talk
            </h2>
            <form class="welcome-form">
              <label class="welcome-form-label">Name</label>
              <input type="text" class="welcome-form-input" :class="[
                darkMode ? 'darkmode' : 'lightmode',
                contactFormErrorName ? 'error' : 'normal',
              ]" v-model="contactFormName" />
              <label class="welcome-form-label">Email</label>
              <input type="text" class="welcome-form-input" :class="[
                darkMode ? 'darkmode' : 'lightmode',
                contactFormErrorEmail ? 'error' : 'normal',
              ]" v-model="contactFormEmail" />
              <label class="welcome-form-label">Message</label>
              <textarea class="welcome-form-input area" :class="[
                darkMode ? 'darkmode' : 'lightmode',
                contactFormErrorMessage ? 'error' : 'normal',
              ]" onkeyup="this.style.height = '1px' ;this.style.height = 25 + this.scrollHeight + 'px'"
                v-model="contactFormMessage"></textarea>
              <input type="button" class="welcome-form-submit" :class="darkMode ? 'darkmode' : 'lightmode'" value="send"
                @click="validateForm" />
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="footer" :class="darkMode ? 'darkmode' : 'lightmode'">
      <div class="welcome-line"></div>
      <div class="welcome-footer-container">
        <div class="welcome-footer-cat">
          <label class="welcome-copyright">&copy; 2023 - Imagify Visual Studio</label>
        </div>
        <div class="welcome-footer-cat">
          <h2>Pages</h2>
          <ul>
            <li v-for="(cli, index) in categoryList" v-bind:key="index" :class="darkMode ? 'darkmode' : 'lightmode'"
              @click="changeScreen(index + 2)">
              {{ cli.name }}
            </li>
          </ul>
        </div>
        <div class="welcome-footer-cat">
          <h2>Info</h2>
          <ul>
            <li @click="navigateFooter(1)" :class="darkMode ? 'darkmode' : 'lightmode'">
              About us
            </li>
            <li @click="navigateFooter(2)" :class="darkMode ? 'darkmode' : 'lightmode'">
              Contact
            </li>
          </ul>
        </div>
      </div>
      <div class="welcome-copy-container">
        <label class="welcome-copyright">&copy; 2023 - Imagify Visual Studio</label>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
import Project from "@/components/Project.vue";

import ImagifyCategory from "@/lib/ImagifyCategory.js";

import emailjs from "@emailjs/browser";

export default {
  name: "Home",
  data() {
    return {
      menuOpen: false,
      currentScreen: 1,
      maxScreens: 2,
      darkMode: false,
      darkModeText: false,
      imageSlide: false,
      welcomePageTitle: "undefined",
      welcomePageDescription: "undefined",
      welcomePageImage: "",
      welcomeText1: "",
      welcomeText2: "",
      cmsURL: process.env.VUE_APP_CMS_URL,
      pagehome: true,
      projectopenanimation: false,
      lastVal: 0,
      contactFormName: "",
      contactFormEmail: "",
      contactFormMessage: "",
      contactFormErrorName: false,
      contactFormErrorEmail: false,
      contactFormErrorMessage: false,
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  mixins: [ImagifyCategory],
  components: {
    Menu,
    Project,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    );

    let isDarkMode = localStorage.getItem("darkmode");
    if (isDarkMode == null) {
      localStorage.setItem("darkmode", true);
      isDarkMode = "true";
    }
    if (isDarkMode == "false") {
      this.darkMode = false;
      this.darkModeText = false;
    } else {
      this.darkMode = true;
      this.darkModeText = true;
    }

    // Get Data
    this.getWelcomePageData();

    window.addEventListener("keydown", (e) => {
      switch (e.key) {
        case "Left":
        case "ArrowLeft":
          this.previousScreen();
          break;
        case "Right":
        case "ArrowRight":
          this.nextScreen();
          break;
        default:
          return;
      }
    });

    let root = document.documentElement;

    document.addEventListener("scroll", () => {
      var lastKnownScrollPosition = window.scrollY;
      var windowHeight = window.innerHeight;

      var windowPercentScroll = Math.round(
        (lastKnownScrollPosition / windowHeight) * 100
      );
      var windowIncrementScroll = 1 - windowPercentScroll / 60;

      root.style.setProperty(
        "--scroll-slider-top",
        "-" + (windowPercentScroll / 2 + "vh")
      );

      root.style.setProperty("--scroll-fader", windowIncrementScroll);
      root.style.setProperty(
        "--scroll-maskout",
        50 - windowPercentScroll / 2 + "vw"
      );
    });
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  methods: {
    toggleMenu: function () {
      this.menuOpen = !this.menuOpen;
      setTimeout(
        function (app) {
          app.$refs.menuChild.toggleMenu();
        },
        250,
        this
      );
    },
    toggleMode: function () {
      if (this.darkMode == false) {
        localStorage.setItem("darkmode", true);
      } else {
        localStorage.setItem("darkmode", false);
      }

      this.darkModeText = !this.darkModeText;
      setTimeout(
        function (outerapp) {
          outerapp.imageSlide = true;

          setTimeout(
            function (app) {
              app.darkMode = !app.darkMode;
              setTimeout(
                function (innerApp) {
                  innerApp.imageSlide = false;
                },
                1000,
                app
              );
            },
            500,
            outerapp
          );
        },
        400,
        this
      );
    },
    closeMenu: function () {
      this.menuOpen = false;
    },
    previousScreen: function () {
      this.imageSlide = true;

      setTimeout(
        function (app) {
          if (app.currentScreen > 1) {
            app.currentScreen--;
            console.log(app.currentScreen);
            if (app.currentScreen == 1) {
              app.$router.push({
                path: "/",
              });
            } else {
              app.$router.push({
                path:
                  "/" +
                  app.categoryList[app.currentScreen - 2].name.toLowerCase(),
              });
            }
          } else {
            app.currentScreen = app.maxScreens;
            app.$router.push({
              path:
                "/" +
                app.categoryList[app.currentScreen - 2].name.toLowerCase(),
            });
          }
          setTimeout(
            function (innerApp) {
              innerApp.imageSlide = false;

              if (innerApp.currentScreen == 1) {
                document.title = "Imagify Visual Studio";
              } else {
                let title =
                  innerApp.capitalize(
                    innerApp.$router.currentRoute.value.params.categoryname
                  ) + " | Imagify Visual Studio";
                document.title = title;
              }
            },
            50,
            app
          );
        },
        500,
        this
      );
    },
    nextScreen: function () {
      this.imageSlide = true;
      setTimeout(
        function (app) {
          if (app.currentScreen < app.maxScreens) {
            app.currentScreen++;

            app.$router.push({
              path:
                "/" +
                app.categoryList[app.currentScreen - 2].name.toLowerCase(),
            });
          } else {
            app.currentScreen = 1;
            app.$router.push({
              path: "/",
            });
          }
          setTimeout(
            function (innerApp) {
              innerApp.imageSlide = false;

              if (innerApp.currentScreen == 1) {
                document.title = "Imagify Visual Studio";
              } else {
                let title =
                  innerApp.capitalize(
                    innerApp.$router.currentRoute.value.params.categoryname
                  ) + " | Imagify Visual Studio";
                document.title = title;
              }
            },
            50,
            app
          );
        },
        500,
        this
      );
    },
    changeScreen: function (screen) {
      if (screen == 1) {
        this.$router.push("/");
      } else {
        this.$router.push(
          "/" + this.categoryList[screen - 2].name.toLowerCase()
        );
      }

      window.scrollTo({ top: 0, behavior: "smooth" });
      this.imageSlide = true;
      setTimeout(
        function (app) {
          app.currentScreen = screen;
          setTimeout(
            function (innerApp) {
              innerApp.imageSlide = false;

              if (screen == 1) {
                document.title = "Imagify Visual Studio";
              } else {
                let title =
                  app.capitalize(
                    app.$router.currentRoute.value.params.categoryname
                  ) + " | Imagify Visual Studio";
                document.title = title;
              }
            },
            50,
            app
          );
        },
        500,
        this
      );
    },

    capitalize: function (s) {
      return s[0].toUpperCase() + s.slice(1);
    },
    navigateFooter: function (item) {
      if (item == 1) {
        this.imageSlide = true;
        setTimeout(
          function (app) {
            app.currentScreen = 1;
            setTimeout(
              function (innerApp) {
                innerApp.imageSlide = false;
              },
              50,
              app
            );
          },
          500,
          this
        );

        setTimeout(() => {
          document.getElementById("aboutus").scrollIntoView({
            behavior: "smooth",
          });
        }, 500);
      } else if (item == 2) {
        this.imageSlide = true;
        setTimeout(
          function (app) {
            app.currentScreen = 1;
            setTimeout(
              function (innerApp) {
                innerApp.imageSlide = false;
              },
              50,
              app
            );
          },
          500,
          this
        );

        setTimeout(() => {
          document.getElementById("contact").scrollIntoView({
            behavior: "smooth",
          });
        }, 500);
      }
    },
    getWelcomePageData: function () {
      let currentScreen = this.$router.currentRoute.value.fullPath.substring(1);
      let routeCat = this.$router.currentRoute.value.params.categoryname;
      let routeProject = this.$router.currentRoute.value.params.projectname;

      console.log("currentScreen: " + currentScreen);
      console.log("routeCat: " + routeCat);
      console.log("routeProject: " + routeProject);

      if (routeCat == undefined) {
        document
          .querySelector('meta[name="description"]')
          .setAttribute(
            "content",
            "We are visual content creators based in Luxembourg. No idea is too crazy or too complex. And that's exactly our focus on how we plan an create our visual content."
          );

        this.axios.get("/api/welcome-page?populate=*").then((response) => {
          this.welcomePageTitle = response.data.data.attributes.Title;
          this.welcomePageDescription =
            response.data.data.attributes.Description;

          let imagePrefix = process.env.VUE_APP_CMS_URL;

          this.welcomePageImage =
            imagePrefix +
            response.data.data.attributes.Cover.data.attributes.url;

          this.welcomeText1 = response.data.data.attributes.Text1;
          this.welcomeText2 = response.data.data.attributes.Text2;
        });
      } else {
        this.welcomePageTitle = "Loading content...";
        this.welcomePageDescription = "Loading content...";
        this.welcomeText1 = "Loading content...";
        this.welcomeText2 = "Loading content...";

        document.title = this.capitalize(routeCat) + " | Imagify Visual Studio";
      }
    },
    handleScroll: function () {
      if (this.menuOpen == true) {
        let newVal = window.scrollY;
        if (this.lastVal == 0) {
          this.lastVal = newVal;
        } else {
          if (newVal > this.lastVal) {
            this.toggleMenu();

            console.log("Last: " + this.lastVal);
            console.log("New: " + newVal);

            this.lastVal = 0;
          } else {
            this.lastVal = newVal;
          }
        }
      }
    },
    openProject: function (projectid) {
      this.projectopenanimation = true;
      let projectnumber = parseInt(
        this.$router.currentRoute.value.params.projectname
      );

      let title =
        this.categoryList[this.currentScreen - 2].projects[0][projectid]
          .attributes.Name;
      document.title =
        title +
        " | " +
        this.capitalize(this.$router.currentRoute.value.params.categoryname) +
        " | Imagify Visual Studio";

      let description =
        this.categoryList[this.currentScreen - 2].projects[0][projectid]
          .attributes.Description;

      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", description);

      if (projectnumber != projectid) {
        this.$router.push(
          "/" +
          this.$router.currentRoute.value.params.categoryname +
          "/" +
          projectid
        );
      }

      // Close projects
      var visibles = document.getElementsByClassName("visible");
      while (visibles.length) {
        visibles[0].classList.remove("visible");
      }

      var projectcontenthide =
        document.getElementsByClassName("project-content");
      for (var u = 0; u < projectcontenthide.length; u++) {
        if (u != projectid) {
          projectcontenthide[u].classList.remove("hide");
        }
      }

      var projects = document.getElementsByClassName("projectopen");
      var element = document.getElementById("project-" + projectid);
      if (!element.classList.contains("projectopen")) {
        // Close projects
        while (projects.length) {
          projects[0].classList.remove("projectopen");
        }

        element.classList.add("projectopen");

        var projectcontent =
          element.getElementsByClassName("project-content")[0];
        if (projectcontent) {
          projectcontent.classList.add("projectopen");
        }

        setTimeout(
          function (app) {
            var projectinfo = element.getElementsByClassName("project-info")[0];
            projectinfo.classList.add("visible");

            var projectline = element.getElementsByClassName("project-line")[0];
            projectline.classList.add("visible");

            var projectview = element.getElementsByClassName("project-view")[0];
            projectview.classList.add("visible");

            var projectviewclose =
              element.getElementsByClassName("project-view-close")[0];
            projectviewclose.classList.add("visible");

            var projectcontent =
              element.getElementsByClassName("project-content")[0];

            if (projectcontent) {
              projectcontent.classList.add("visible");
            }

            var projectimagecontainer = element.getElementsByClassName(
              "project-image-container"
            )[0];
            projectimagecontainer.classList.add("visible");

            app.pagehome = false;

            setTimeout(function () {
              var projectcontent =
                document.getElementsByClassName("project-content");
              if (projectcontent) {
                for (var u = 0; u < projectcontent.length; u++) {
                  if (u != projectid) {
                    projectcontent[u].classList.add("hide");
                  }
                }
              }
            }, 500);
          },
          50,
          this
        );
      } else {
        // Close projects
        while (projects.length) {
          projects[0].classList.remove("projectopen");
        }
      }
    },
    validateForm: function () {
      let fName = this.contactFormName;
      let fEmail = this.contactFormEmail;
      let fMessage = this.contactFormMessage;

      this.contactFormErrorName = false;
      this.contactFormErrorEmail = false;
      this.contactFormErrorMessage = false;

      let allGood = true;
      // Verify name
      if (fName == "") {
        allGood = false;
        this.contactFormErrorName = true;
      }

      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!fEmail.match(validRegex)) {
        allGood = false;
        this.contactFormErrorEmail = true;
      }

      if (fMessage == "") {
        allGood = false;
        this.contactFormErrorMessage = true;
      }

      if (allGood) {
        this.$recaptchaLoaded().then(() => {
          this.$recaptcha("login").then(() => {
            const templateParams = {
              name: fName,
              email: fEmail,
              message: fMessage,
            };

            emailjs
              .send(
                "service_l93snfo",
                "template_xro3w5b",
                templateParams,
                "gUM4VbWfCi4pol-y4"
              )
              .then(() => {
                alert("Your message has been send.");
              });
          });
        });

        // Send Email
      }
    },
    getDimensions() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;

      console.log("Width: " + this.width + " | Height: " + this.height);
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight / 100}px`
      );
    },
  },
  watch: {
    categoryList(newlist) {
      let currentScreen = this.$router.currentRoute.value.params.categoryname;
      let currentScreenNumber = 0;
      for (var i = 0; i < newlist.length; i++) {
        if (newlist[i].name.toLowerCase() == currentScreen) {
          currentScreenNumber = i + 2;

          document
            .querySelector('meta[name="description"]')
            .setAttribute("content", newlist[i].description);
        }
      }
      if (currentScreenNumber != 0) {
        this.currentScreen = currentScreenNumber;

        this.axios.get("/api/welcome-page?populate=*").then((response) => {
          this.welcomePageTitle = response.data.data.attributes.Title;
          this.welcomePageDescription =
            response.data.data.attributes.Description;

          let imagePrefix = process.env.VUE_APP_CMS_URL;

          this.welcomePageImage =
            imagePrefix +
            response.data.data.attributes.Cover.data.attributes.url;

          this.welcomeText1 = response.data.data.attributes.Text1;
          this.welcomeText2 = response.data.data.attributes.Text2;

          if (this.$router.currentRoute.value.params.projectname != undefined) {
            console.log("loading project");
            let projectnumber = parseInt(
              this.$router.currentRoute.value.params.projectname
            );


            setTimeout(
              function (that) {
                that.openProject(projectnumber);
                document
                  .getElementById("project-" + projectnumber)
                  .scrollIntoView();
              },
              250,
              this
            );
          }
        });
      }
    },
  },
};
</script>

<style>
.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  height: 100vh;

  z-index: 1000;
  background-image: linear-gradient(30deg, #f76325, #e75155, #d73f86, #bb20d8);

  display: flex;

  justify-content: flex-start;
  align-items: flex-end;
}

.menubutton {
  padding: 0;
  margin: 0 0 0 1em;

  cursor: pointer;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 14pt;

  color: white;
}

.menubutton.darkmode {
  color: white;
}

.menubutton.lightmode {
  color: #3c3c3c;
}

.menubuttoncontainer:hover .menubutton.hidden {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 0;
}

.menubutton.hidden {
  opacity: 0;
  transform: translateX(100%);
  transition: all 400ms;
  transition-delay: 150ms;

  z-index: 10;

  display: flex;
}

.menubutton.main {
  z-index: 100;
}

.menulogocontainer {
  position: absolute;

  top: 3vh;
  left: 3vw;
  z-index: 50;

  overflow: hidden;
  display: flex;
}

.menubuttoncontainer {
  position: fixed;

  top: 3vh;
  right: 3vw;
  z-index: 50;

  overflow: hidden;

  display: flex;
}

.letter-container-static {
  position: relative;
}

.letter-change-container {
  position: relative;

  width: 45pt;
}

.letter-container {
  position: absolute;
  right: 0;
}

.menubutton-letter {
  transform: translateY(0);
  display: inline-block;
}

.slideletter-enter-active,
.slideletter-leave-active {
  transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1);
}

.slideletter-enter-from,
.slideletter-leave-to {
  transform: translateY(120%);
}

.slidedownmenubtn-enter-active,
.slidedownmenubtn-leave-active {
  transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1);
}

.slidedownmenubtn-enter-from,
.slidedownmenubtn-leave-to {
  transform: translateY(120%);
}

.slidedownmenubtnmode-enter-active,
.slidedownmenubtnmode-leave-active {
  transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1);
}

.slidedownmenubtnmode-enter-from,
.slidedownmenubtnmode-leave-to {
  transform: translateY(120%) !important;
}

.home {
  height: 100%;
  background-color: white;
  max-width: 100%;

  transition: background-color 1500ms 400ms;
}

.home-dark {
  height: 100%;
  max-width: 100%;
  background-color: #111;
  transition: background-color 1500ms 400ms;
}

.page-home {
  display: flex;
  height: 100%;
}

.page-home .section {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-items: flex-end;
  flex-direction: row;

  height: 100%;

  overflow: hidden;
}

.section:nth-child(1) {
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.section {
  max-width: 100%;
}

.section h1 {
  margin: 0;
  padding: 0 0 1.5em 0;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 16pt;

  transition: transform 500ms, color 250ms;
}

.section p {
  width: 25vw;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 28pt;

  margin: 0;
  padding: 0;

  transition: transform 500ms, color 250ms;
}

.overflowhidden {
  overflow: hidden;
  width: fit-content;
}

.textstill {
  transition-delay: 350ms !important;
  transform: translateY(0);
}

.textslideout {
  transform: translateY(100%) !important;
  transition-delay: 0 !important;
}

.textslidesidestill {
  transition-delay: 200ms !important;
  transform: translateX(0);

  width: fit-content;
}

.textslideside {
  transform: translateX(-120%) !important;
  transition-delay: 0 !important;
}

.section-content {
  margin: 4vw 4vw 2vw 4vw;

  overflow: hidden;
}

.section-content.darkmode {
  color: white;
}

.section-content.lightmode {
  color: #555555;
}

.image-container-1 {
  background-position: center;
  background-size: cover;

  height: 100vh;
  width: 50vw;

  margin: 0 0 0 0;

  transition: width 500ms;
  animation-timing-function: cubic-bezier(0.1, 0.1, 1, 0);

  transition-delay: 500ms;
}

.image-container-1.maskout {
  width: 0;
  opacity: 1;
  transition-delay: 0ms;
}

.image-container-2 {
  height: 100vh;
  width: 50vw;

  margin: 0 0 0 0;

  transition: all 500ms;
  animation-timing-function: cubic-bezier(0.1, 0.1, 1, 0);

  transition-delay: 500ms;
}

.image-container-2.maskout {
  width: 0vw;
  opacity: 1;
  transition-delay: 0ms;
}

.image-container-column {
  display: flex;
  flex-direction: column;

  align-items: flex-end;
}

.navigation {
  margin: 0 0 8vh 4vw;
  display: flex;
}

.navigation-element {
  flex: 1;

  display: flex;
  flex-direction: row;

  align-items: center;
}

.navigation-element.lightmode .navigation-text {
  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 12pt;

  margin: 0;

  color: #a0a0a0;
  transition: color 250ms;
}

.navigation-element.lightmode .navigation-line {
  height: 1px;
  width: 0;

  border-left: 10vw solid #a0a0a0;

  transition: border 250ms;
}

.navigation-element.darkmode .navigation-text {
  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 12pt;

  margin: 0;

  color: #3c3c3c;
  transition: color 250ms;
}

.navigation-element.darkmode .navigation-line {
  height: 1px;
  width: 0;

  border-left: 10vw solid #3c3c3c;

  transition: border 250ms;
}

.navigation-element:nth-child(1) .navigation-text {
  padding: 0 1vw 0 0;
}

.navigation-element:nth-child(2) .navigation-text {
  padding: 0 0 0 1vw;
}

.navigation-element.lightmode:hover .navigation-line {
  border-left: 10vw solid #555555;
}

.navigation-element.lightmode:hover .navigation-text {
  color: #555555;
  cursor: pointer;
}

.navigation-element.lightmode:hover .navigation-text label {
  color: #555555;
  cursor: pointer;
}

.navigation-element.darkmode:hover .navigation-line {
  border-left: 10vw solid white;
}

.navigation-element.darkmode:hover .navigation-text {
  color: white;
  cursor: pointer;
}

.navigation-element.darkmode:hover .navigation-text label {
  color: white;
  cursor: pointer;
}

.navigation-arrow {
  font-size: 5vh !important;

  cursor: pointer;
}

.navigation-arrow-reverse {
  font-size: 5vh !important;
  transform: rotateY(180deg);
  cursor: pointer;
}

.navigation-button {
  color: #707070;
}

.project-outer {
  display: flex;
  padding: 20vh 0 0 0;
  max-width: 100%;
}

.home-projects {
  margin: 0;
  padding: 0 0 15vw 0;
  position: relative;
  z-index: 100;
}

.home-projects.lightmode {
  background-color: white;
  transition: background-color 1500ms 400ms;
}

.home-projects.darkmode {
  background-color: #111;

  transition: background-color 1500ms 400ms;
}

.home-projects.darkmode .project-number {
  color: white;
}

.home-projects.darkmode .project-header {
  color: white;
}

.home-projects.darkmode .project-description {
  color: white;
}

.home-projects.darkmode .project-line {
  background-color: white;
}

.home-projects.darkmode .project-view {
  color: white;
}

.home-projects.darkmode .project-view-close {
  color: white;
}

.footer.lightmode {
  margin: 0 0 0 0;
  width: 100%;

  background-color: white;
  position: relative;
  z-index: 50;
}

.footer.darkmode {
  margin: 0 0 0 0;
  width: 100%;

  background-color: #111;
  position: relative;
  z-index: 50;
}

.parallax-container {
  position: fixed;
  right: 0;
  width: 50vw;
}

.parallax-container div {
  transform: translateY(calc(var(--scroll-slider-top) / 2));
}

.project-wrapper:nth-child(odd) .project-outer {
  justify-content: left;
}

.project-wrapper:nth-child(even) .project-outer {
  justify-content: right;
  flex-direction: row-reverse;
}

.projectopen .project-info {
  display: flex;
}

.home-projects.welcome {
  padding: 0;
}

.welcome-container {
  display: flex;
}

.welcome-flex {
  flex: 1;
}

.welcome-text1 {
  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 28pt;

  color: white;

  text-align: left;

  padding: 4vw 4vw 8vw 4vw;
}

.welcome-text1.lightmode {
  color: #555555;
}

.welcome-text2 {
  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 18pt;

  color: white;

  text-align: left;

  padding: 8vw 4vw 4vw 4vw;
}

.welcome-text2.lightmode {
  color: #555555;
}

.welcome-line {
  width: 92vw;
  height: 1px;

  background-color: #3c3c3c;
  margin-left: 4vw;
}

.welcome-contact {
  display: flex;
}

.welcome-contact div {
  flex: 1;
}

.welcome-contact-address {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;

  padding: 4vw;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 12pt;

  color: white;
}

.welcome-contact-address.lightmode {
  color: #555555;
}

.welcome-contact-address-item.last {
  padding: 2vw 0 0 0;
}

.welcome-contact-heading {
  margin: 0 0 0 4vw;
  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 28pt;

  padding-bottom: 2vh;
}

.welcome-contact-heading.lightmode {
  color: #555555;
}

.welcome-contact.second {
  margin: 10vh 0 0 0;
}

.welcome-form {
  display: flex;
  flex-direction: column;

  align-content: flex-start;
  align-items: flex-start;

  margin: 0 0 0 4vw;

  width: 80%;
}

.welcome-form-label {
  color: #3c3c3c;
  margin: 2vh 0 0 0;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 12pt;
}

.welcome-form-input {
  width: 100%;
  height: 4vh;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 12pt;

  background-color: transparent;

  border: none;
  border-bottom: 1px solid #3c3c3c;
  color: white;
}

.welcome-form-input.lightmode {
  color: #555555;
}

.welcome-form-input.error {
  border-bottom: 1px solid red;
}

.welcome-form-input.area {
  padding: 1vh 0 0 0;
}

.welcome-form-input.area.lightmode {
  color: #555555;
}

.welcome-form-input:focus {
  background-color: transparent;
  box-shadow: none;
  outline: none;
}

.welcome-form-submit {
  background-color: transparent;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 12pt;
  color: white;
  border: none;

  align-self: flex-end;

  margin: 5vh 0 0 0;
}

.welcome-form-submit:hover {
  cursor: pointer;
  text-decoration: underline;
}

.welcome-form-submit.lightmode {
  color: #555555;
}

.welcome-footer-container {
  width: 100vw;
  margin-left: 0;
  padding-bottom: 4vh;
  display: flex;

  align-content: flex-start;
  align-items: flex-start;
}

.welcome-copy-container {
  align-content: flex-start;
  align-items: flex-start;

  display: none;
}

.welcome-footer-cat {
  flex: 1;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
}

.welcome-footer-cat:nth-child(1) {
  flex: 2;
}

.welcome-footer-cat h2 {
  font-family: "BeVietnamPro";
  font-weight: 600;
  font-size: 11pt;
  color: #3c3c3c;
  margin: 2vh 0 2vh 4vw;
}

.welcome-footer-cat ul {
  margin: 0 0 0 4vw;
  padding: 0;
}

.welcome-footer-cat li {
  margin: 0;
  padding: 0;

  list-style-type: none;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 11pt;
  line-height: 16pt;
  color: #3c3c3c;

  text-align: left;
}

.welcome-footer-cat li.darkmode:hover {
  color: white;
  cursor: pointer;
}

.welcome-footer-cat li.lightmode:hover {
  color: #999;
  cursor: pointer;
}

.welcome-copyright {
  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 11pt;
  color: #3c3c3c;
  padding: 2vh 0 2vh 4vw;
  position: relative;
}

.section.mobile {
  display: none;
}

.home-welcome-screen-1 {
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.home-welcome-screen-2 {
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@keyframes blinkingdarkline {
  0% {
    border-left: 10vw solid #3c3c3c;
  }

  50% {
    border-left: 10vw solid #ffffff;
  }

  100% {
    border-left: 10vw solid #3c3c3c;
  }
}

@keyframes blinkingdark {
  0% {
    color: #3c3c3c;
  }

  50% {
    color: white;
  }

  100% {
    color: #3c3c3c;
  }
}

.navigation-element.darkmode .navigation-line.anim {
  -webkit-animation: blinkingdarkline 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
  -moz-animation: blinkingdarkline 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
  -ms-animation: blinkingdarkline 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
  -o-animation: blinkingdarkline 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
  animation: blinkingdarkline 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
}

.navigation-element.darkmode .navigation-text.anim {
  -webkit-animation: blinkingdark 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
  -moz-animation: blinkingdark 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
  -ms-animation: blinkingdark 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
  -o-animation: blinkingdark 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
  animation: blinkingdark 4s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite;
}

.menubutton.mode.lightmode {
  color: #3c3c3c;
}

.menubutton.mode.darkmode {
  color: white;
}

.welcome-line.mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .page-home {
    flex-direction: column-reverse !important;
    height: calc(var(--vh, 1vh) * 100);
  }

  .menu {
    height: calc(var(--vh, 1vh) * 100);
  }

  .menubutton.main.lightmode {
    color: white;
  }

  .section p {
    width: 80vw;
    font-size: 1.25em;
  }

  .parallax-container {
    position: relative;
  }

  .navigation {
    margin: 2vh 0 4vh 4vw;
  }

  .parallax-container {
    display: none !important;
  }

  .navigation {}

  .welcome-text1 {
    font-size: 1.25em;
  }

  .welcome-text2 {
    font-size: 1.25em;
  }

  .welcome-container {
    flex-direction: column;
  }

  .welcome-contact {
    flex-direction: column;
  }

  .menulogowhite {
    width: 50vw;
  }

  .menulogowhitecontainer {
    padding: 0.5vh 0 0 0;
  }

  .section.mobile {
    display: flex;
  }

  .image-container-1 {
    background-position: center;
    background-size: cover;

    height: calc(var(--vh, 1vh) * 50);
    width: 100vw;

    margin: 0 0 0 0;

    transition: width 500ms;
    animation-timing-function: cubic-bezier(0.1, 0.1, 1, 0);

    transition-delay: 500ms;
  }

  .image-container-1.maskout {
    width: 0;
    opacity: 1;
    transition-delay: 0ms;
  }

  .menubutton.hidden {
    transition: all 400ms;

    z-index: 10;

    display: flex;
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0;
  }

  .menubutton.logo.lightmode {
    color: white;
  }

  .menubutton.logo {
    font-size: 1em;
  }

  .menubutton.hidden {
    font-size: 1em;
  }

  .menubutton.main {
    font-size: 1em;
  }

  .navigation-element:first-child {
    justify-content: flex-end;
  }

  .welcome-footer-container {
    width: 100vw;
    margin-left: 0;
    display: flex;

    padding: 5vh 0 5vh 0;
  }

  .menubutton.mode.lightmode {
    color: white;
  }

  .project-wrapper:nth-child(even) .project-outer {
    justify-content: left;
    flex-direction: column;
  }

  .welcome-footer-cat:nth-child(1) {
    display: none;
  }

  .welcome-copy-container {
    display: flex;
  }
}
</style>
