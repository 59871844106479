<template>
  <transition name="slideup">
    <div class="menu" v-if="menuopen">
      <div class="menulogowhitecontainer">
        <transition name="slidedownmenuwhitebtn">
          <img
            src="/assets/logow.svg"
            class="menulogowhite"
            v-if="menuitemsvisible"
          />
        </transition>
      </div>
      <div class="menubuttonwhitecontainer">
        <transition name="slidedownmenuwhitebtn">
          <h1
            class="menubuttonwhite"
            v-if="menuitemsvisible"
            @click="toggleMenu"
          >
            close
          </h1>
        </transition>
      </div>

      <!--
			{{ showtest }}
			<div class="menuitemcontainer">
				<transition name="slideup">
					<h1 v-if="showtest" class="menuitemtest">test</h1>
				</transition>
			</div>
			-->

      <ul class="menu-item-list">
        <div class="menu-item-container">
          <transition name="slideup1">
            <li v-if="menuitemsvisible" @click="menuItem('hi')">hi there</li>
          </transition>
        </div>
        <div class="menu-item-container">
          <transition name="slideup2">
            <li v-if="menuitemsvisible" @click="menuItem('work')">work</li>
          </transition>
        </div>
        <div class="menu-item-container">
          <transition name="slideup3">
            <li v-if="menuitemsvisible" @click="menuItem('about')">about us</li>
          </transition>
        </div>
        <div class="menu-item-container">
          <transition name="slideup4">
            <li v-if="menuitemsvisible" @click="menuItem('contact')">
              get in touch
            </li>
          </transition>
        </div>
      </ul>
    </div>
  </transition>
</template>

<script>
let menuTimeout = 250;
let menuTimeoutClose = 500;

export default {
  data() {
    return {
      menuopen: false,
      showtest: false,
      menuitemsvisible: false,
    };
  },
  mounted() {},
  methods: {
    toggleMenu: function () {
      if (this.menuopen == false) {
        this.showMenu();
      } else {
        this.hideMenu();
      }
    },
    showMenu: function () {
      this.menuopen = true;
      document.getElementById("top").scrollIntoView({
        behavior: "smooth",
      });
      setTimeout(
        function (app) {
          app.menuitemsvisible = true;
        },
        menuTimeout,
        this
      );
    },
    hideMenu: function () {
      this.menuitemsvisible = false;
      setTimeout(
        function (app) {
          app.menuopen = false;
          app.$emit("closeMenu", "asd");
        },
        menuTimeoutClose,
        this
      );
    },
    menuItem(menu) {
      if (menu == "about") {
        this.$emit("customScreen", 1);
        this.hideMenu();
        setTimeout(() => {
          document.getElementById("aboutus").scrollIntoView({
            behavior: "smooth",
          });
        }, 500);
      } else if (menu == "contact") {
        this.$emit("customScreen", 1);

        this.hideMenu();
        setTimeout(() => {
          document.getElementById("contact").scrollIntoView({
            behavior: "smooth",
          });
        }, 500);
      } else if (menu == "work") {
        this.$emit("customScreen", 2);
        this.hideMenu();
      } else if (menu == "hi") {
        this.$emit("customScreen", 1);
        this.hideMenu();
      }
    },
  },
};
</script>

<style>
.menubuttonwhite {
  margin: 0;
  padding: 0;

  cursor: pointer;

  font-family: "BeVietnamPro";
  font-weight: 400;
  font-size: 14pt;

  color: white;
}

.slideup-enter-active,
.slideup-leave-active {
  transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1);
}
/* Menu Item 1 */
.slideup1-enter-active,
.slideup2-enter-active,
.slideup3-enter-active,
.slideup4-enter-active,
.slideup1-leave-active,
.slideup2-leave-active,
.slideup3-leave-active,
.slideup4-leave-active {
  transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1);
}

.slideup-enter-from,
.slideup1-enter-from,
.slideup2-enter-from,
.slideup3-enter-from,
.slideup4-enter-from,
.slideup-leave-to,
.slideup1-leave-to,
.slideup2-leave-to,
.slideup3-leave-to,
.slideup4-leave-to {
  transform: translateY(120%);
}

.slideup-enter-active {
  transition-delay: 0;
}

.slideup1-enter-active,
.slideup1-leave-to {
  transition-delay: 100ms;
}
.slideup2-enter-active,
.slideup2-leave-to {
  transition-delay: 150ms;
}
.slideup3-enter-active,
.slideup3-leave-to {
  transition-delay: 200ms;
}
.slideup4-enter-active,
.slideup4-leave-to {
  transition-delay: 250ms;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.menuitemcontainer {
  overflow: hidden;
}

.menuitemtest {
  line-height: 100%;
  padding: 0;
  margin: 0;
}

.menu-item-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin-bottom: 5vh;
}

.menu-item-list li {
  list-style-type: none;
  font-family: "BeVietnamPro";
  font-weight: 400;

  color: white;

  font-size: 28pt;
}

.menu-item-container {
  overflow: hidden;
  transition: transform 100ms ease-in-out;
  transform-origin: left;
  opacity: 0.85;
}

.menu-item-container:hover {
  cursor: pointer;
  opacity: 1;
}

.slidedownmenuwhitebtn-enter-active,
.slidedownmenuwhitebtn-leave-active {
  transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1);
}

.slidedownmenuwhitebtn-enter-from {
  transform: translateY(120%);
}
.slidedownmenuwhitebtn-leave-to {
  transform: translateY(120%);
}

.slidedownmenuwhitebtn-enter-active {
  transition-delay: 500ms;
}

.menubuttonwhitecontainer {
  position: fixed;

  top: 3vh;
  right: 3vw;
  z-index: 50;

  overflow: hidden;
}

.menulogowhitecontainer {
  position: fixed;

  top: 3vh;
  left: 3vw;
  z-index: 50;

  overflow: hidden;
}

.menulogowhite {
  transform-box: fill-box;
  width: 14vw;
}
</style>
