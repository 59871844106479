import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

let locale = window.location.pathname.replace(/^\/([^/]+).*/i, "$1");

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/:categoryname",
    name: "Category",
    component: Home,
  },
  {
    path: "/:categoryname/:projectname",
    name: "Project",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(
    locale.trim().length && locale != "/" ? "/" + locale : undefined
  ),
  routes,
});

export default router;
