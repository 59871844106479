export default {
  name: "ImagifyCategory",
  data() {
    return {
      categoryList: [],
    };
  },
  async mounted() {
    await this.getCategoryIDList();
  },
  methods: {
    async getCategoryIDList() {
      var categoryIDResult = await this.axios.get(
        "/api/category-sort?populate[Sorter][populate]=CategoryID"
      );

      let idList = categoryIDResult.data.data.attributes.Sorter;

      var categoryList = [];

      for (var i = 0; i < idList.length; i++) {
        let currentID = idList[i].CategoryID.data.id;
        let currentName = idList[i].CategoryID.data.attributes.Name;
        let currentDescription =
          idList[i].CategoryID.data.attributes.Description;

        var categoryResult = await this.axios.get(
          "/api/categories/" + currentID + "?populate=*"
        );

        let query =
          "https://cms.imagify.lu/api/projects?filters[categories]=" +
          currentID +
          "&populate=*";

        // Search project new relation
        let currentURL =
          process.env.VUE_APP_CMS_URL +
          categoryResult.data.data.attributes.Cover.data.attributes.url;

        var projectsResult = await this.axios.get(query);

        let currentProjectData = [];
        for (var j = 0; j < projectsResult.data.data.length; j++) {
          currentProjectData.push(projectsResult.data.data);
        }

        let categoryObj = {
          id: currentID,
          name: currentName,
          description: currentDescription,
          url: currentURL,
          projects: currentProjectData,
        };

        categoryList.push(categoryObj);
      }

      this.categoryList = categoryList;
      this.maxScreens = categoryList.length + 1;
    },
  },
};
